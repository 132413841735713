/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addBioPicture } from '../fn/shop/add-bio-picture';
import { AddBioPicture$Params } from '../fn/shop/add-bio-picture';
import { AddedShopBioPicture } from '../models/added-shop-bio-picture';
import { AddedShopPicture } from '../models/added-shop-picture';
import { addShopPicture } from '../fn/shop/add-shop-picture';
import { AddShopPicture$Params } from '../fn/shop/add-shop-picture';
import { browseShops } from '../fn/shop/browse-shops';
import { BrowseShops$Params } from '../fn/shop/browse-shops';
import { browseShopTransfers } from '../fn/shop/browse-shop-transfers';
import { BrowseShopTransfers$Params } from '../fn/shop/browse-shop-transfers';
import { deleteShopPicture } from '../fn/shop/delete-shop-picture';
import { DeleteShopPicture$Params } from '../fn/shop/delete-shop-picture';
import { enableShop } from '../fn/shop/enable-shop';
import { EnableShop$Params } from '../fn/shop/enable-shop';
import { finalizeShopBioPicture } from '../fn/shop/finalize-shop-bio-picture';
import { FinalizeShopBioPicture$Params } from '../fn/shop/finalize-shop-bio-picture';
import { finalizeShopPicture } from '../fn/shop/finalize-shop-picture';
import { FinalizeShopPicture$Params } from '../fn/shop/finalize-shop-picture';
import { readShop } from '../fn/shop/read-shop';
import { ReadShop$Params } from '../fn/shop/read-shop';
import { readShopByOrigin } from '../fn/shop/read-shop-by-origin';
import { ReadShopByOrigin$Params } from '../fn/shop/read-shop-by-origin';
import { readShopBySlug } from '../fn/shop/read-shop-by-slug';
import { ReadShopBySlug$Params } from '../fn/shop/read-shop-by-slug';
import { readShopByUser } from '../fn/shop/read-shop-by-user';
import { ReadShopByUser$Params } from '../fn/shop/read-shop-by-user';
import { removeShopBioPicture } from '../fn/shop/remove-shop-bio-picture';
import { RemoveShopBioPicture$Params } from '../fn/shop/remove-shop-bio-picture';
import { Shop } from '../models/shop';
import { ShopList } from '../models/shop-list';
import { TransferList } from '../models/transfer-list';
import { updateShop } from '../fn/shop/update-shop';
import { UpdateShop$Params } from '../fn/shop/update-shop';
import { updateShopBio } from '../fn/shop/update-shop-bio';
import { UpdateShopBio$Params } from '../fn/shop/update-shop-bio';
import { validateShopCustomDomain } from '../fn/shop/validate-shop-custom-domain';
import { ValidateShopCustomDomain$Params } from '../fn/shop/validate-shop-custom-domain';
import { validateShopSlug } from '../fn/shop/validate-shop-slug';
import { ValidateShopSlug$Params } from '../fn/shop/validate-shop-slug';

@Injectable({ providedIn: 'root' })
export class ShopService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `browseShopTransfers()` */
  static readonly BrowseShopTransfersPath = '/public/shops/user/{userId}/browse';

  /**
   * Browse shop transfers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseShopTransfers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseShopTransfers$Response(params: BrowseShopTransfers$Params, context?: HttpContext): Observable<StrictHttpResponse<TransferList>> {
    return browseShopTransfers(this.http, this.rootUrl, params, context);
  }

  /**
   * Browse shop transfers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseShopTransfers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseShopTransfers(params: BrowseShopTransfers$Params, context?: HttpContext): Observable<TransferList> {
    return this.browseShopTransfers$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransferList>): TransferList => r.body)
    );
  }

  /** Path part for operation `readShop()` */
  static readonly ReadShopPath = '/private/shops/me';

  /**
   * ReadShop.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readShop()` instead.
   *
   * This method doesn't expect any request body.
   */
  readShop$Response(params?: ReadShop$Params, context?: HttpContext): Observable<StrictHttpResponse<Shop>> {
    return readShop(this.http, this.rootUrl, params, context);
  }

  /**
   * ReadShop.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readShop$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readShop(params?: ReadShop$Params, context?: HttpContext): Observable<Shop> {
    return this.readShop$Response(params, context).pipe(
      map((r: StrictHttpResponse<Shop>): Shop => r.body)
    );
  }

  /** Path part for operation `updateShop()` */
  static readonly UpdateShopPath = '/private/shops';

  /**
   * UpdateShop.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateShop()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateShop$Response(params?: UpdateShop$Params, context?: HttpContext): Observable<StrictHttpResponse<Shop>> {
    return updateShop(this.http, this.rootUrl, params, context);
  }

  /**
   * UpdateShop.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateShop$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateShop(params?: UpdateShop$Params, context?: HttpContext): Observable<Shop> {
    return this.updateShop$Response(params, context).pipe(
      map((r: StrictHttpResponse<Shop>): Shop => r.body)
    );
  }

  /** Path part for operation `validateShopSlug()` */
  static readonly ValidateShopSlugPath = '/private/shops/slug/validate';

  /**
   * ValidateShopSlug.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateShopSlug()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateShopSlug$Response(params?: ValidateShopSlug$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateShopSlug(this.http, this.rootUrl, params, context);
  }

  /**
   * ValidateShopSlug.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateShopSlug$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateShopSlug(params?: ValidateShopSlug$Params, context?: HttpContext): Observable<void> {
    return this.validateShopSlug$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `validateShopCustomDomain()` */
  static readonly ValidateShopCustomDomainPath = '/private/shops/customDomain/validate';

  /**
   * ValidateShopCustomDomain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateShopCustomDomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateShopCustomDomain$Response(params?: ValidateShopCustomDomain$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return validateShopCustomDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * ValidateShopCustomDomain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateShopCustomDomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  validateShopCustomDomain(params?: ValidateShopCustomDomain$Params, context?: HttpContext): Observable<void> {
    return this.validateShopCustomDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `readShopByUser()` */
  static readonly ReadShopByUserPath = '/public/shops/user/{userId}';

  /**
   * ReadShopByUser.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readShopByUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  readShopByUser$Response(params: ReadShopByUser$Params, context?: HttpContext): Observable<StrictHttpResponse<Shop>> {
    return readShopByUser(this.http, this.rootUrl, params, context);
  }

  /**
   * ReadShopByUser.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readShopByUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readShopByUser(params: ReadShopByUser$Params, context?: HttpContext): Observable<Shop> {
    return this.readShopByUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<Shop>): Shop => r.body)
    );
  }

  /** Path part for operation `readShopBySlug()` */
  static readonly ReadShopBySlugPath = '/public/shops/slug/{shopSlug}';

  /**
   * ReadShopBySlug.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readShopBySlug()` instead.
   *
   * This method doesn't expect any request body.
   */
  readShopBySlug$Response(params: ReadShopBySlug$Params, context?: HttpContext): Observable<StrictHttpResponse<Shop>> {
    return readShopBySlug(this.http, this.rootUrl, params, context);
  }

  /**
   * ReadShopBySlug.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readShopBySlug$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readShopBySlug(params: ReadShopBySlug$Params, context?: HttpContext): Observable<Shop> {
    return this.readShopBySlug$Response(params, context).pipe(
      map((r: StrictHttpResponse<Shop>): Shop => r.body)
    );
  }

  /** Path part for operation `readShopByOrigin()` */
  static readonly ReadShopByOriginPath = '/public/shops/origin';

  /**
   * ReadShopByOrigin.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readShopByOrigin()` instead.
   *
   * This method doesn't expect any request body.
   */
  readShopByOrigin$Response(params: ReadShopByOrigin$Params, context?: HttpContext): Observable<StrictHttpResponse<Shop>> {
    return readShopByOrigin(this.http, this.rootUrl, params, context);
  }

  /**
   * ReadShopByOrigin.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readShopByOrigin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readShopByOrigin(params: ReadShopByOrigin$Params, context?: HttpContext): Observable<Shop> {
    return this.readShopByOrigin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Shop>): Shop => r.body)
    );
  }

  /** Path part for operation `browseShops()` */
  static readonly BrowseShopsPath = '/admin/shops/browse';

  /**
   * Browse shops.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `browseShops()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseShops$Response(params?: BrowseShops$Params, context?: HttpContext): Observable<StrictHttpResponse<ShopList>> {
    return browseShops(this.http, this.rootUrl, params, context);
  }

  /**
   * Browse shops.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `browseShops$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  browseShops(params?: BrowseShops$Params, context?: HttpContext): Observable<ShopList> {
    return this.browseShops$Response(params, context).pipe(
      map((r: StrictHttpResponse<ShopList>): ShopList => r.body)
    );
  }

  /** Path part for operation `addShopPicture()` */
  static readonly AddShopPicturePath = '/private/shops/{shopId}/picture';

  /**
   * AddShopPicture.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addShopPicture()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addShopPicture$Response(params: AddShopPicture$Params, context?: HttpContext): Observable<StrictHttpResponse<AddedShopPicture>> {
    return addShopPicture(this.http, this.rootUrl, params, context);
  }

  /**
   * AddShopPicture.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addShopPicture$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addShopPicture(params: AddShopPicture$Params, context?: HttpContext): Observable<AddedShopPicture> {
    return this.addShopPicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddedShopPicture>): AddedShopPicture => r.body)
    );
  }

  /** Path part for operation `deleteShopPicture()` */
  static readonly DeleteShopPicturePath = '/private/shops/{shopId}/picture';

  /**
   * DeleteShopPicture.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteShopPicture()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteShopPicture$Response(params: DeleteShopPicture$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteShopPicture(this.http, this.rootUrl, params, context);
  }

  /**
   * DeleteShopPicture.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteShopPicture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteShopPicture(params: DeleteShopPicture$Params, context?: HttpContext): Observable<void> {
    return this.deleteShopPicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `finalizeShopPicture()` */
  static readonly FinalizeShopPicturePath = '/private/shops/{shopId}/picture/{pictureId}/finalize';

  /**
   * FinalizeShopPicture.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finalizeShopPicture()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeShopPicture$Response(params: FinalizeShopPicture$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return finalizeShopPicture(this.http, this.rootUrl, params, context);
  }

  /**
   * FinalizeShopPicture.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finalizeShopPicture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeShopPicture(params: FinalizeShopPicture$Params, context?: HttpContext): Observable<void> {
    return this.finalizeShopPicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateShopBio()` */
  static readonly UpdateShopBioPath = '/private/shops/bio';

  /**
   * updateShopBio.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateShopBio()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateShopBio$Response(params?: UpdateShopBio$Params, context?: HttpContext): Observable<StrictHttpResponse<Shop>> {
    return updateShopBio(this.http, this.rootUrl, params, context);
  }

  /**
   * updateShopBio.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateShopBio$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateShopBio(params?: UpdateShopBio$Params, context?: HttpContext): Observable<Shop> {
    return this.updateShopBio$Response(params, context).pipe(
      map((r: StrictHttpResponse<Shop>): Shop => r.body)
    );
  }

  /** Path part for operation `addBioPicture()` */
  static readonly AddBioPicturePath = '/private/shops/{shopId}/bio/picture';

  /**
   * AddShopBioPicture.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBioPicture()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBioPicture$Response(params: AddBioPicture$Params, context?: HttpContext): Observable<StrictHttpResponse<AddedShopBioPicture>> {
    return addBioPicture(this.http, this.rootUrl, params, context);
  }

  /**
   * AddShopBioPicture.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addBioPicture$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addBioPicture(params: AddBioPicture$Params, context?: HttpContext): Observable<AddedShopBioPicture> {
    return this.addBioPicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddedShopBioPicture>): AddedShopBioPicture => r.body)
    );
  }

  /** Path part for operation `removeShopBioPicture()` */
  static readonly RemoveShopBioPicturePath = '/private/shops/{shopId}/bio/picture';

  /**
   * removeShopBioPicture.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeShopBioPicture()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeShopBioPicture$Response(params: RemoveShopBioPicture$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeShopBioPicture(this.http, this.rootUrl, params, context);
  }

  /**
   * removeShopBioPicture.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeShopBioPicture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeShopBioPicture(params: RemoveShopBioPicture$Params, context?: HttpContext): Observable<void> {
    return this.removeShopBioPicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `finalizeShopBioPicture()` */
  static readonly FinalizeShopBioPicturePath = '/private/shops/{shopId}/bio/picture/{pictureId}/finalize';

  /**
   * finalizeShopBioPicture.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `finalizeShopBioPicture()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeShopBioPicture$Response(params: FinalizeShopBioPicture$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return finalizeShopBioPicture(this.http, this.rootUrl, params, context);
  }

  /**
   * finalizeShopBioPicture.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `finalizeShopBioPicture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  finalizeShopBioPicture(params: FinalizeShopBioPicture$Params, context?: HttpContext): Observable<void> {
    return this.finalizeShopBioPicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `enableShop()` */
  static readonly EnableShopPath = '/private/shops/enable';

  /**
   * enableShop.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableShop()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enableShop$Response(params?: EnableShop$Params, context?: HttpContext): Observable<StrictHttpResponse<Shop>> {
    return enableShop(this.http, this.rootUrl, params, context);
  }

  /**
   * enableShop.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enableShop$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  enableShop(params?: EnableShop$Params, context?: HttpContext): Observable<Shop> {
    return this.enableShop$Response(params, context).pipe(
      map((r: StrictHttpResponse<Shop>): Shop => r.body)
    );
  }

}
